import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/en';

const About = (props) => (
  <Layout bodyClass="page-about" location={props.location}>
    <SEO title="About Us"/>
    <div className="intro heading">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center mb-2">About Us</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="container pb-3 pt-3">
      <div className="row">
        <div className="col-10 mb-1 offset-1">
          <div className="about">
            <div className="about-meta">
              <h2 className="about-title text-center text-dark">Team</h2>
            </div>
            <div
              className="about-content"
            >
              <blockquote>
                <p className="text-center">In Massam Corporate Consulting, we have members with plentiful business
                  experience in different
                  fields across continents. With the team, we are able to incorporate different culture and practice
                  from different jurisdictions into our innovative and literal ideas on your business.</p>
              </blockquote>
            </div>
          </div>
        </div>

        <div className="col-10 mb-1 offset-1">
          <div className="about">
            <div className="about-meta">
              <h2 className="about-title text-center text-dark">Vision</h2>
            </div>
            <div
              className="about-content"
            >
              <blockquote>
                <p className="text-center">Our vision is simple but meaningful: be the solution for your business.
                  With Massam Corporate
                  Consulting, you will enjoy an efficient use of time and resources to deploy and expand your core
                  business. We always welcome you to come to us with any hassles and we are here to help.</p>
              </blockquote>
            </div>
          </div>
        </div>

        <div className="col-10 mb-1 offset-1">
          <div className="about">
            <div className="about-meta">
              <h2 className="about-title text-center text-dark">Prospective</h2>
            </div>
            <div
              className="about-content"
            >
              <blockquote>
                <p className="text-center">We are looking forward to including your business in our prestigious
                  clientele which is comprised
                  from local businesses to multinational corporations. Be welcomed to discuss with us for a further
                  growth!</p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;
